import { UseQuery, useQuery } from 'shared/hooks/useQuery';
import { Trim } from 'features/detailed-comparator/types';
import { Product } from 'shared/constants';
import { Brand } from 'shared/types';

interface Response {
  derivatives?: Trim[];
}

const useDerivatives = ({
  modelId,
  product,
  registrationYear,
  brand,
}: {
  modelId: string;
  product: Product;
  registrationYear?: string;
  brand: Brand;
}): UseQuery<Response> => {
  return useQuery<Response>(Boolean(modelId), `/api/derivatives/${brand}/${modelId}`, product);
};

export { useDerivatives };
